@mixin a($e) { @extend [data-aa~="#{$e}"]; }
@mixin d($e) { @extend [data-dd~="#{$e}"]; }
@mixin g($e) { @extend [data-gg~="#{$e}"]; }





[data-pp~="t:1"] {
	@include d("ft-fy=main-rr");
	@include d("ft-se=30rem");
	@include d("lr-sg=1rem");
	@include d("le-ht=1");
}

[data-pp~="t:2"] {
	@include d("ft-fy=main-rr");
	@include d("ft-se=12rem");
	@include d("lr-sg=1rem");
	@include d("le-ht=1.2");
	@include d("tt-an=lt");
}

[data-pp~="t:3"] {
	@include d("ft-fy=main-rr");
	@include d("ft-se=20rem");
	@include d("lr-sg=1rem");
	@include d("le-ht=1");
}





// Checkbox
[data-pp~="1:1"] {
	&:hover,
	&:focus-within,
	&:checked {
		+ [data-pp~="1:2"] {

			[data-pp~="1:3"] {
				background-color: var(--color-we);

				[data-pp~="1:4"] {
					color: var(--color-bk);
				}
			}
		}
	}
}



// Carousel
[data-pp~="2:1"] {
    background-color: var(--color-3) !important;

    > span {
        background-color: var(--color-we) !important;
    }
}

[data-pp~="2:2"] {
    background-color: var(--color-4) !important;

    > span {
        background-color: var(--color-bk) !important;
    }
}



[data-pp~="3:1"] {
	&:checked {
		~ [data-pp~="3:2"] {
			border-color: var(--color-bk);

			[data-pp~="3:3"] {
				color: var(--color-bk);
			}
			[data-pp~="3:4"] {
				background-color: transparent;
			}
		}
	}
}



[data-pp~="4:1"] {
	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(8),
	&:nth-child(9),
	&:nth-child(10) {
		width: 33.3333333333%;

		@media (max-width: 1000px) {
			width: 50%;
		}
	}
}
