@media (max-width: 2000px) {
    [data-dd~="@2000=ft-se=12rem"] { font-size: 12rem }
    [data-dd~="@2000=ft-se=26rem"] { font-size: 26rem }
    [data-dd~="@2000=ft-se=28rem"] { font-size: 28rem }


    [data-dd~="@2000=pg=10px"] { padding: 10px }
    [data-dd~="@2000=pg=20px"] { padding: 20px }


    [data-dd~="@2000=mn-lt=il"] { margin-left: initial }
    [data-dd~="@2000=mn-lt=40px"] { margin-left: 40px }


    [data-dd~="@2000=an-dn=40s"] { animation-duration: 40s }

}



@media (max-width: 1600px) {
    [data-dd~="@1600=wh=35px"] { width: 35px }
    [data-dd~="@1600=wh=80px"] { width: 80px }


    [data-dd~="@1600=ft-se=11rem"] { font-size: 11rem }
    [data-dd~="@1600=ft-se=18rem"] { font-size: 18rem }
    [data-dd~="@1600=ft-se=22rem"] { font-size: 22rem }
    [data-dd~="@1600=ft-se=26rem"] { font-size: 26rem }
    [data-dd~="@1600=ft-se=30rem"] { font-size: 30rem }
    [data-dd~="@1600=ft-se=40rem"] { font-size: 40rem }


    [data-dd~="@1600=pg=10px"] { padding: 10px }
    [data-dd~="@1600=pg=20px"] { padding: 20px }

    [data-dd~="@1600=pg-tp=40px"] { padding-top: 40px }

    [data-dd~="@1600=pg-bm=20px"] { padding-bottom: 20px }
    [data-dd~="@1600=pg-bm=30px"] { padding-bottom: 30px }
    [data-dd~="@1600=pg-bm=40px"] { padding-bottom: 40px }


    [data-dd~="@1600=mn-tp=10px"] { margin-top: 10px }

    [data-dd~="@1600=mn-bm=-30px"] { margin-bottom: -30px }

    [data-dd~="@1600=mn-lt=20px"] { margin-left: 20px }

}



@media (max-width: 1500px) {
    [data-dd~="@1500=an-dn=30s"] { animation-duration: 30s }

}



@media (max-width: 1450px) {
    [data-dd~="@1450=ft-se=24rem"] { font-size: 24rem }

    [data-dd~="@1450=mn-lt=il"] { margin-left: initial }
    [data-dd~="@1450=mn-lt=20px"] { margin-left: 20px }

}



@media (max-width: 1300px) {
    [data-dd~="@1300=ft-se=22rem"] { font-size: 22rem }

}



@media (max-width: 1200px) {}



@media (max-width: 1000px) {
    [data-dd~="@1000=gd-aa=il"] { grid-area: initial }


    [data-dd~="@1000=at-ro=il"] { aspect-ratio: initial }
    [data-dd~="@1000=at-ro=70"] { aspect-ratio: 100 / 70 }


    [data-dd~="@1000=wh=il"] { width: initial }
    [data-dd~="@1000=wh=25px"] { width: 25px }
    [data-dd~="@1000=wh=30px"] { width: 30px }
    [data-dd~="@1000=wh=40px"] { width: 40px }
    [data-dd~="@1000=wh=50px"] { width: 50px }
    [data-dd~="@1000=wh=60px"] { width: 60px }
    [data-dd~="@1600=wh=50%"] { width: 50% }


    [data-dd~="@1000=ht=30px"] { height: 30px }
    [data-dd~="@1000=ht=50px"] { height: 50px }
    [data-dd~="@1000=ht=150px"] { height: 150px }


    [data-dd~="@1000=mn-ht=il"] { min-height: initial }


    [data-dd~="@1000=dy=ne"] { display: none }
    [data-dd~="@1000=dy=fx"] { display: flex }


    [data-dd~="@1000=pn=il"] { position: initial }
    [data-dd~="@1000=pn=fd"] { position: fixed }

    [data-dd~="@1000=tp=10px"] { top: 10px }

    [data-dd~="@1000=rt=10px"] { right: 10px }
    [data-dd~="@1000=rt=20px"] { right: 20px }

    [data-dd~="@1000=lt=10px"] { left: 10px }
    [data-dd~="@1000=lt=20px"] { left: 20px }


    [data-dd~="@1000=or=1"] { order: 1 }
    [data-dd~="@1000=or=2"] { order: 2 }
    [data-dd~="@1000=or=3"] { order: 3 }


    [data-dd~="@1000=ft-se=12rem"] { font-size: 12rem }
    [data-dd~="@1000=ft-se=14rem"] { font-size: 14rem }
    [data-dd~="@1000=ft-se=16rem"] { font-size: 16rem }
    [data-dd~="@1000=ft-se=18rem"] { font-size: 18rem }
    [data-dd~="@1000=ft-se=20rem"] { font-size: 20rem }
    [data-dd~="@1000=ft-se=30rem"] { font-size: 30rem }

    [data-dd~="@1000=co=bk"] { color: var(--color-bk) }


    [data-dd~="@1000=tt-an=cr"] { text-align: center }


    [data-dd~="@1000=pg=il"] { padding: initial }
    [data-dd~="@1000=pg=5px"] { padding: 5px }
    [data-dd~="@1000=pg=10px"] { padding: 10px }
    [data-dd~="@1000=pg=30px0"] { padding: 30px 0 }

    [data-dd~="@1000=pg-tp=il"] { padding-top: initial }
    [data-dd~="@1000=pg-tp=10px"] { padding-top: 10px }
    [data-dd~="@1000=pg-tp=20px"] { padding-top: 20px }
    [data-dd~="@1000=pg-tp=30px"] { padding-top: 30px }
    [data-dd~="@1000=pg-tp=40px"] { padding-top: 40px }

    [data-dd~="@1000=pg-rt=il"] { padding-right: initial }

    [data-dd~="@1000=pg-bm=il"] { padding-bottom: initial }
    [data-dd~="@1000=pg-bm=10px"] { padding-bottom: 10px }
    [data-dd~="@1000=pg-bm=30px"] { padding-bottom: 30px }

    [data-dd~="@1000=pg-lt=il"] { padding-left: initial }


    [data-dd~="@1000=mn-tp=5px"] { margin-top: 5px }
    [data-dd~="@1000=mn-tp=10px"] { margin-top: 10px }
    [data-dd~="@1000=mn-tp=20px"] { margin-top: 20px }
    [data-dd~="@1000=mn-tp=40px"] { margin-top: 40px }

    [data-dd~="@1000=mn-rt=il"] { margin-right: initial }
    [data-dd~="@1000=mn-rt=10px"] { margin-right: 10px }

    [data-dd~="@1000=mn-bm=5px"] { margin-bottom: 5px }
    [data-dd~="@1000=mn-bm=20px"] { margin-bottom: 20px }

    [data-dd~="@1000=mn-lt=il"] { margin-left: initial }
    [data-dd~="@1000=mn-lt=5px"] { margin-left: 5px }
    [data-dd~="@1000=mn-lt=10px"] { margin-left: 10px }
    [data-dd~="@1000=mn-lt=40px"] { margin-left: 40px }
    [data-dd~="@1000=:nt(:ft-cd)=mn-lt=il"]:not(:first-child) { margin-left: initial }


    [data-dd~="@1000=bx-sw=1"] { box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 15%) }


    [data-dd~="@1000=an-dn=20s"] { animation-duration: 20s }

    [data-dd~="@1000=br-lt-cr=3"] { border-left-color: var(--color-3) }

}



@media (max-width: 600px) {
    [data-dd~="@600=wh=100%"] { width: 100% }


    [data-dd~="@600=ft-se=16rem"] { font-size: 16rem }


    [data-dd~="@600=tt-an=cr"] { text-align: center }


    [data-dd~="@600=dy=ne"] { display: none }


    [data-dd~="@600=or=1"] { order: 1 }
    [data-dd~="@600=or=2"] { order: 2 }
    [data-dd~="@600=or=3"] { order: 3 }


    [data-dd~="@600=pg=40px10px"] { padding: 40px 10px }


    [data-dd~="@600=mn-tp=10px"] { margin-top: 10px }
    [data-dd~="@600=mn-tp=20px"] { margin-top: 20px }
    [data-dd~="@600=mn-tp=40px"] { margin-top: 40px }

    [data-dd~="@600=mn-lt=il"] { margin-left: initial }

}



@media (max-width: 500px) {
    [data-dd~="@500=an-dn=10s"] { animation-duration: 10s }

}
